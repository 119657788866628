<template>
    <div  class="auth-wrapper auth-v2">
        <b-row class="auth-inner m-0">

            <!-- Brand logo-->
            
            <!-- /Brand logo-->

            <!-- Left Text-->
            <b-col
                lg="7"
                class="d-none d-lg-flex align-items-center p-5"
            >
                <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
                    <b-img
                        fluid
                        :src="imgUrl"
                        alt="Register"
                        class="ren-side-img"
                    />
                </div>
            </b-col>
            <!-- /Left Text-->

            <!-- Register-->
            <b-col
                lg="5"
                class="d-flex align-items-center auth-bg px-2 p-lg-5"
            >
                <div class="ren-picker">
                    <v-select
                        v-model="locale"
                        :options="languages"
                        label="text"
                        :clearable="false"
                    />
                </div>
                <b-col
                    sm="8"
                    md="6"
                    lg="12"
                    class="px-xl-2 mx-auto mt-3"
                    v-if="!registered"
                >
                    <b-card-title
                        class="mb-1"
                        title-tag="h2"
                    >
                        {{i18nT(`Ouvrir un compte gratuit`)}}
                    </b-card-title>
                    <b-card-text class="mb-1">
                        {{i18nT(`Créer de meilleures relations entre les propriétaires et les locataires !`)}}
                    </b-card-text>

                    <div class="demo-inline-spacing pl-1 mb-1">
                        <b-form-radio
                            v-model="userType"
                            value="landlord"
                        >
                            {{i18nT(`Je suis`)}} <strong>{{ i18nT(`Propriétaire`) }}</strong>
                        </b-form-radio>
                        <b-form-radio
                            v-model="userType"
                            value="tenant"
                        >
                            {{i18nT(`Je suis `)}} <strong>{{ i18nT(`Locataire`) }}</strong>
                        </b-form-radio>
                    </div>

                    <!-- form -->
                    <validation-observer
                        ref="registerForm"
                        v-if="userType === 'landlord'"
                    >
                        <b-form
                            class="auth-register-form mt-2"
                            @submit.prevent="register"
                        >

                            <!-- HIDE SELECTOR FOR ACCOUNT TYPE -->
                            <b-row v-if="false" class="mb-1">
                                <b-col lg="12">
                                    <b-form-group
                                        :label="i18nT(`Account type`)"
                                        label-for="register-firstname"
                                    >
                                        <b-form-select
                                            v-model="userType"
                                            :options="userTypeOptions"
                                        />
                                    </b-form-group>
                                </b-col>
                            </b-row>


                            <b-row>
                                <b-col
                                    lg="6"
                                >
                                    <!-- firstname -->
                                    <b-form-group
                                        :label="i18nT(`Prénom`)"
                                        label-for="register-firstname"
                                        class="required"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="Prénom"
                                            vid="firstname"
                                            rules="required"
                                        >
                                            <b-form-input
                                                id="register-firstname"
                                                v-model="firstname"
                                                name="register-firstname"
                                                class="required"
                                                :state="errors.length > 0 ? false:null"
                                                :placeholder="i18nT(`Anna`)"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col
                                    lg="6"
                                >
                                    <!-- lastname -->
                                    <b-form-group
                                        :label="i18nT(`Nom`)"
                                        label-for="register-lastname"
                                        class="required"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="Nom"
                                            vid="lastname"
                                            rules="required"
                                        >
                                            <b-form-input
                                                id="register-lastname"
                                                v-model="lastname"
                                                name="register-lastname"
                                                :state="errors.length > 0 ? false:null"
                                                :placeholder="i18nT(`Bell`)"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row>

                            <!-- email -->
                            <b-form-group
                                :label="i18nT(`Email`)"
                                label-for="register-email"
                                class="required"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="Email"
                                    vid="email"
                                    rules="required|email"
                                >
                                    <b-form-input
                                        id="register-email"
                                        v-model="userEmail"
                                        name="register-email"
                                        :state="errors.length > 0 ? false:null"
                                        :placeholder="i18nT(`workemail@company.com`)"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>

                            <!-- password -->
                            <b-form-group
                                label-for="register-password"
                                :label="i18nT(`Passe`)"
                                class="required"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="Passe"
                                    vid="password"
                                    rules="required|passwordRule"
                                >
                                    <b-input-group
                                        class="input-group-merge required"
                                        :class="errors.length > 0 ? 'is-invalid':null"
                                    >
                                        <b-form-input
                                            id="register-password"
                                            v-model="password"
                                            class="form-control-merge"
                                            :type="passwordFieldType"
                                            :state="errors.length > 0 ? false:null"
                                            name="register-password"
                                            :placeholder="i18nT(`············`)"
                                        />
                                        <b-input-group-append is-text>
                                            <feather-icon
                                                :icon="passwordToggleIcon"
                                                class="cursor-pointer"
                                                @click="togglePasswordVisibility"
                                            />
                                        </b-input-group-append>
                                    </b-input-group>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
<!--                              TODO - password rules needs to be localized-->
                            <div class="password-rules font-small-3">
                                <div class="pb-1">{{i18nT(`To ensure that your account and information is well protected, please chose a password that has: `)}}</div>
                                <div class="d-flex">
                                    <div class="rule-column pr-2">
                                        <div :class="{ 'rule-met': isLengthValid }"><font-awesome-icon :icon="isLengthValid ? `check` : `times`" class="mr-1" :class="isLengthValid ? `text-success`: `text-danger`"></font-awesome-icon>{{i18nT(`Minimum 8 characters`)}}</div>
                                        <div :class="{ 'rule-met': hasUppercase }"><font-awesome-icon :icon="hasUppercase ? `check` : `times`" class="mr-1" :class="hasUppercase ? `text-success`: `text-danger`"></font-awesome-icon>{{i18nT(`At least one uppercase letter`)}}</div>
                                    </div>
                                    <div class="rule-column">
                                        <div :class="{ 'rule-met': hasNumber }"><font-awesome-icon :icon="hasNumber ? `check` : `times`" class="mr-1" :class="hasNumber ? `text-success`: `text-danger`"></font-awesome-icon>{{i18nT(`At least one number`)}}</div>
                                        <div :class="{ 'rule-met': hasSpecialCharacter }"><font-awesome-icon :icon="hasSpecialCharacter ? `check` : `times`" class="mr-1" :class="hasSpecialCharacter ? `text-success`: `text-danger`"></font-awesome-icon>{{i18nT(`At least one special character`)}}</div>
                                    </div>
                                </div>
                                <!-- Add other rules as needed -->
                            </div>


                            <b-form-group class="mt-2">
                                <validation-provider
                                    :name="i18nT(`Recevoir notre Newsletter`)"
                                >
                                    <b-input-group
                                    >
                                        <b-form-checkbox
                                            id="register-newsletter"
                                            v-model="newsletter"
                                            name="newsletter"
                                            class="pl-2"

                                        >
                                            {{ i18nT(`Recevoir notre Newsletter`) }}
                                        </b-form-checkbox>
                                    </b-input-group>
                                </validation-provider>
                            </b-form-group>

                            <b-form-group class="mt-2">
                                <validation-provider
                                    :name="i18nT(`Recevoir nos offres spéciales`)"
                                >
                                    <b-input-group
                                    >
                                        <b-form-checkbox
                                            id="register-special-offers"
                                            v-model="specialOffers"
                                            name="newsletter"
                                            class="pl-2"

                                        >
                                            {{ i18nT(`Recevoir nos offres spéciales`) }}
                                        </b-form-checkbox>
                                    </b-input-group>
                                </validation-provider>
                            </b-form-group>

                            <b-button
                                variant="primary"
                                block
                                type="submit"
                                :disabled="loading"
                            >
                                {{i18nT(`Inscription`)}}
                            </b-button>
                        </b-form>
                    </validation-observer>

                    <p v-else class="ren-tenant-msg">
                        {{ i18nT(`Merci de demander à votre bailleur de vous inviter.`) }}
                        <br /> <br/>
                        {{i18nT(`Une fois l'invitation reçue par e-mail, suivez le lien pour confirmer votre inscription.`)}}
                    </p>

                    <p class="text-center mt-2 ">
                        <span>{{i18nT(`Déjà client?`)}} </span>
                        <b-link :to="{name:'auth-login', query: {lang: locale.value}}">
                            <span> {{i18nT(`Cliquez ici`)}}</span>
                        </b-link>
                    </p>

                    <b-card-text class="text-center mt-2">
                        <p class="m-2 ">
                            <small>
                                {{i18nT(`En vous inscrivant, vous reconnaissez avoir lu et accepté les`)}}
                              <b-link :href="`https://www.rentila.com/`+locale.value+`/privacy`" target="_blank">{{i18nT(`Conditions d'utilisation `)}}</b-link>
                              {{i18nT(`et la`)}} <b-link :href="`https://www.rentila.com/`+locale.value+`/terms`"  target="_blank">{{i18nT(`Politique de confidentialité.`)}}</b-link>.
                            </small>
                        </p>
                        <b-img
                            fluid
                            :src="gdprImg"
                            class="d-inline mr-50"
                            width="80"
                        />

                        <b-img
                            fluid
                            :src="sslImg"
                            class="d-inline"
                            width="80"
                        />
                    </b-card-text>

                </b-col>

                <b-col
                    sm="8"
                    md="6"
                    lg="12"
                    class="px-xl-2 mx-auto mt-3"
                    v-if="registered"
                >      
                    <b-card-title
                        class="mb-1 text-center"
                        title-tag="h2"
                    >
                        <h2>{{ i18nT(`Account created successfully`) }}!</h2>
                    </b-card-title>

                    <b-card-text class="mb-2 text-center">
                        <p>
                            {{i18nT(`Welcome to Rentila and thank you for choosing us`)}}! {{i18nT(`We already help`)}}
                            <strong>{{i18nT(`tens of thousnads of landlords `)}}</strong>{{i18nT(`with their management`)}}! {{i18nT(`You will be in very good company`)}}!
                        </p>
                        <p class="ren-underline">
                            <strong>{{i18nT(`You will receive an email to activate your account. Please click the link in the confirmation email to complete the registration.`)}}</strong>
                        </p>
                        <p>
                            {{i18nT(`If you do not reveice a confirmation email with 24 hours of your membership, remember to check the `)}}
                            <strong>{{i18nT(`Junk Mail (Spam) `)}}</strong>
                            {{i18nT(`folder in your email box`)}}.
                        </p>
                    </b-card-text>

                    <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
                        <b-img
                            fluid
                            :src="imgUrl"
                            alt="Register"
                        />
                     </div>

                     <div class="mt-4 text-center">
                        <b-button
                            variant="primary"
                            :to="{name:'auth-login', query: {lang: locale.value}}"
                        >
                            {{i18nT(`LOGIN TO YOUR ACCOUNT`)}}
                        </b-button>
                     </div>
                </b-col>
            </b-col>
            <!-- /Register-->
        </b-row>
    </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import {
    BRow,
    BCol,
    BLink,
    BButton,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BCardTitle,
    BCardText,
    BFormSelect,
    BOverlay
} from 'bootstrap-vue'
import { required, email, passwordRule } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import vSelect from 'vue-select'
import store from '@/store/index'

// import useAuth from '@/auth/useAuth'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import {dictToSelectArray} from "@core/utils/utils";


export default {
    components: {
        BOverlay,
        BRow,
        BImg,
        BCol,
        BLink,
        BButton,
        BForm,
        BCardText,
        BCardTitle,
        BFormCheckbox,
        BFormGroup,
        BFormInput,
        BInputGroup,
        BInputGroupAppend,
        // validations
        ValidationProvider,
        ValidationObserver,
        BFormSelect,
        vSelect
    },
    mixins: [togglePasswordVisibility],
    data() {
        return {
            newsletter: false,
            specialOffers: false,
            registered: false,
            firstname: '',
            lastname: '',
            userEmail: '',
            password: '',
            loading: false,
            sideImg: require('@client/images/login/login-v2.svg'),
            gdprImg: require('@/assets/images/icons/gdpr.svg'),
            sslImg: require('@/assets/images/icons/ssl.svg'),
            // validation
            required,
            email,
            passwordRule,
            userType: 'landlord',
            locale: {text: 'French', value: 'fr'},
            languages: [],
        }
    },
    created() {
        extend('passwordRule', {
            validate(value) {
                return value.length >= 8 && /[A-Z]/.test(value) && /\d/.test(value); // Example criteria
            },
            message: this.i18nT(`Password does not meet requirements`),
        });

        this.getLanguages();

    },
    computed: {
        isLengthValid() {
            return this.password.length >= 8;
        },
        hasUppercase() {
            return /[A-Z]/.test(this.password);
        },
        hasSpecialCharacter() {
            return /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(this.password);
        },
        hasNumber() {
            return /\d/.test(this.password);
        },
        passwordToggleIcon() {
            return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },
        imgUrl() {
            if (store.state.appConfig.layout.skin === 'dark') {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.sideImg = require('@client/images/login/login-v2-dark.svg')
                return this.sideImg
            }
            return this.sideImg
        }
    },
    methods: {
        getLanguages() {
            this.$http.get(`languages`). then(({data}) => {
              this.languages = dictToSelectArray(data.data.languages)
              if(this.$route.query.lang) {
                this.locale = this.languages.find(c => c.value === this.$route.query.lang)
                console.log('locale: ', this.locale)
              }
          })
        },
        register() {

            this.$refs.registerForm.validate().then(success => {
                if (success) {
                   
                    this.$store.commit('app/REQUEST_PENDING')
                    this.loading = true;

                    const formData = new FormData()

                    formData.append('user_type', this.userType)
                    formData.append('UserFirstName', this.firstname)
                    formData.append('UserLastName', this.lastname)
                    formData.append('UserEmail', this.userEmail)
                    formData.append('UserPassword', this.password)
                    formData.append('ReceiveNewsletter', this.newsletter ? 1 : 0)
                    formData.append('ReceivePartnerOffers', this.specialOffers ? 1 : 0)

                    this.$http.post('register', formData)
                    .then(({data}) => {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: this.i18nT(`Welcome`),
                                icon: 'CoffeeIcon',
                                variant: 'success',
                                text: this.i18nT(data.message),
                            },
                        })
                        this.loading = false;
                            this.registered = true

                        this.$store.commit('app/REQUEST_DONE')
                    })
                    .catch((error) => {
                        this.loading = false;
                        this.$store.commit('app/REQUEST_DONE')
                        console.log(error)
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: this.errorMessage(error),
                                icon: 'AlertCircleIcon',
                                variant: 'danger',
                            },
                        })
                    });
                }
            })
        },
    },
    watch: {
      locale: {
        handler: function (newVal, oldVal) {
            if(newVal && newVal.value !== 'undefined' && newVal.value !== null && newVal.value !== oldVal.value) {
                useAuth.setLocale(newVal.value)
                this.changeLang(newVal.value) 
                history.pushState({}, null, `${this.$route.path}?lang=${newVal.value}`)
            }
        },
        deep: true,
      },
  },
}
/* eslint-disable global-require */
</script>

<style scoped>
.rule-met {
    color: var(--success);
}

.ren-underline {
    text-decoration: underline;
    text-decoration-color: var(--success);
}

.ren-tenant-msg {
    margin-top: 100px;
    margin-bottom: 324px;
}

.ren-picker {
    width: 150px;
    position: absolute;
    top: 20px;
    right: 20px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import '@core/scss/vue/libs/vue-select.scss';

</style>
